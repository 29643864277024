import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/Layout";
import Works from "../../components/Works";

function WorksPage({ location, data }) {
  return (
    <Layout
      location={location}
      languages={data.site.siteMetadata.languages}
      headerProps={{
        className: "bg-transparent",
      }}
    >
      <Works works={data.works.nodes} />
    </Layout>
  );
}

export default WorksPage;

export const pageQuery = graphql`
  query WorksEnQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    works: allDatoCmsWork(
      filter: { locale: { eq: "en" } }
      sort: { fields: order, order: DESC }
    ) {
      nodes {
        slug
        title
        brand
        challenge {
          value
        }
        mainImage {
          gatsbyImageData(layout: FULL_WIDTH, width: 400, placeholder: BLURRED)
        }
        preview {
          url
        }
        previewImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          alt
        }
      }
    }
  }
`;
